import React from "react";
import "../styles/accounting.css";
const Accounting = () => {
  return (
    <body>
    <div className="accounting-page">
      <h1>Accounting-Page^</h1>
      <br></br>
      <p >Upcomming Accounts will update here.</p>
    </div>
    </body>
  );
};

export default Accounting;
