import React from "react";
import "../styles/gstreturns.css";
const Gstreturn = () => {
  return (
    <body>
    <div className="gst-page">
      <h1>GST-Return Page^</h1>
      <br></br>
      <p >Upcomming GST-Section will update here.</p>
    </div>
    </body>
  );
};

export default Gstreturn;
