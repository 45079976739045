import React from "react";
import "../styles/purchaseinvoice.css";
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PublicSharpIcon from '@mui/icons-material/PublicSharp';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
const Purchaseinvoice = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // Navigates to the previous page in the history stack
  };
  return (
    <div className="purchaseinvoice-page">
    {/* Main Content */}
    <main className="main-content">
        <header className="customer-header">
          <h1>PURCHASE INVOICE</h1>
          <IconButton onClick={handleGoBack} color="primary" aria-label="go back">
        <ArrowCircleLeftOutlinedIcon   sx={{position:'relative',bottom:60,right:10, fontSize: 55, color: 'black' }} /></IconButton>
          <div className="header-actions">
            <input type="text" placeholder="Search for something" />
           <NotificationsIcon  sx={{position:'relative',bottom:110,left:860, fontSize: 35, color: 'black' }}/>
            <PublicSharpIcon sx={{position:'relative',bottom:110,left:730, fontSize: 35, color: 'black' }} />
            <AccountCircleOutlinedIcon sx={{position:'relative',bottom:110,left:880, fontSize: 35, color: 'black' }}/>
            </div>
      
        </header>

        
       
         
      </main>
    </div>
    
  );
};

export default Purchaseinvoice;
