import React, {useState} from "react";
import { useNavigate } from "react-router-dom";
import "../styles/others.css"; // Link your custom styles
import NotificationsIcon from "@mui/icons-material/Notifications";
import PublicSharpIcon from "@mui/icons-material/PublicSharp";
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded';
const Dashboard = () => {
  const navigate = useNavigate();
  const [showProfileOverlay, setShowProfileOverlay] = useState(false); // State for profile overlay
  const [isDark, setIsDark] = useState(false); // State for dark background effect
  const [searchQuery, setSearchQuery] = useState(""); // State for search query
  const [suggestions, setSuggestions] = useState([]); // State for search suggestions

  // List of possible pages/routes
  const pages = [
   "dashboard",
   "sales",
   "items",
   "purchase",
   "masterdata",
   "accounting",
   "gstreturns",
   "others",
   "settings",
   "business-details",
   ];

  const handleProfileClick = () => {
    setShowProfileOverlay(!showProfileOverlay);
    setIsDark(!isDark); // Toggle dark background when profile is clicked
  };

  // Updated handleGoBack to not navigate away from the Dashboard
  const handleGoBack = () => {
    setShowProfileOverlay(false); // Just close the profile overlay
    setIsDark(false); // Optionally reset dark background
  };
 
  // Handle search input change
  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    // Filter pages based on the search query
    if (query.trim() === "") {
      setSuggestions([]); // Clear suggestions if the input is empty
    } else {
      const filteredSuggestions = pages.filter((page) =>
        page.toLowerCase().startsWith(query.toLowerCase())
      );
      setSuggestions(filteredSuggestions);
    }
  };

  // Handle search on Enter key press
  const handleSearch = (event) => {
    if (event.key === "Enter" && searchQuery.trim()) {
      // Navigate to the first matched page
      switch (searchQuery.toLowerCase()) {
        case "sales":
          navigate("/sales");
          break;
        case "dashboard":
          navigate("/dashboard");
          break;
        case "purchase":
          navigate("/purchase");
          break;
        case "accounting":
          navigate("/accounting");
          break;
        case "gstreturns":
          navigate("/gstreturns");
          break;
        case "settings":
          navigate("/settings");
          break;
        case "items":
          navigate("/items");
          break;
        case "masterdata":
          navigate("/masterdata");
          break;
        case "business-details":
          navigate("/business-details");
          break;
          case "others":
          navigate("/others");
          break;
        default:
          alert("Page not found!");
          break;
      }
    }
  };

  return (
    <body>
    <div className="other-container">
   
    
      {/* Main Content */}
      <main className="other-content">
        <header className="other-header">
          <h1>OTHERS</h1>
          <div className="other-actions">
          <input
              type="text"
              placeholder="Search for something"
              value={searchQuery}
              onChange={handleSearchChange} // Track search input
              onKeyDown={handleSearch} // Trigger search on Enter key press
            />
            {/* Show suggestions if there are any */}
            {suggestions.length > 0 && (
              <div className="suggestions-dropdown">
                {suggestions.map((suggestion, index) => (
                  <div
                    key={index}
                    className="suggestion-item"
                    onClick={() => {
                      // Navigate to the selected page when clicked
                      navigate(`/${suggestion}`);
                      setSearchQuery(""); // Clear search input after selection
                      setSuggestions([]); // Clear suggestions
                    }}
                  >
                    {suggestion}
                  </div>
                ))}
              </div>
            )}
              <div className="icons">
            <PublicSharpIcon
            className="public-others"
            fontSize="larger" /* or "small", "", "medium" */
            
            />
            <NotificationsIcon
              className="notification-others"
              fontSize="larger" /* or "small", "", "medium" */  
           
              aria-label="notifications"
            />
           {/* Profile Icon */}
           <img
              src="./profile.png"
            className="profile-others"
              alt="profile"
            
              onClick={handleProfileClick}
            />
              {/* Profile Overlay */}
              {showProfileOverlay && (
                <div className="profile-overlay">
                  <div className="view-profile">
                    {/* Cancel icon now only closes the profile overlay */}
                    <CancelTwoToneIcon
                      onClick={handleGoBack}
                      sx={{
                        position: "absolute",
                        bottom: "295px",
                        right: "5px",
                        color: "red",
                        fontSize: "20px",
                        cursor: "pointer",
                      }}
                    />
                    <img src="./dp.png" alt="dp"></img>
                    <b>EZBillify</b>
                    <p>GSTIN:2964A78DL102865</p>
                    <hr/>
                 <SettingsRoundedIcon 
                  sx={{position:"relative",right:"85px",top:"55px",color:"black",fontSize:"25px"}} />
                   <ManageAccountsRoundedIcon 
                  sx={{position:"relative",right:"110px",top:"20px",color:"black",fontSize:"25px"}} />
                   <PersonAddAltRoundedIcon 
                 sx={{position:"relative",right:"132px",top:"89px",color:"black",fontSize:"25px"}} />
                  <div className="dp-heading">
                    <a  onClick={() => navigate("/Business")}>BUSINESS<c >DETAILS</c> </a>
                    <d  onClick={() => navigate("/settings")} >SETTINGS</d>
                    <e  onClick={() => navigate("/AddUsers")}>  ADD <f>USERS</f></e>
                  </div>
                  
                  </div>
                </div>
              )}
            </div>
          </div>
        </header>

        {/* Buttons */}
        <section className="functional-btn-others1">
            <img src="./Prints.png" alt="Robot" onClick={() => navigate("/prints")} />
            <img src="./Reports.png" alt="Robot" onClick={() => navigate("/reports")}/>
            <img src="./Payroll.png" alt="Robot" onClick={() => navigate("/payroll")} />
          <img src="./Attendance.png" alt="Robot" onClick={() => navigate("/attendance")}/>
            <img src="./Manageemployee.png" alt="Robot" onClick={() => navigate("/manageemployee")} />
        </section>
 {/* Buttons */}
 <section className="functional-btn-others2">
            <img src="./commingsoon.png" alt="Robot"  onClick={() => navigate("/#")} />
          
        </section>
      </main>
    </div>
    </body>
  );
};

export default Dashboard;
