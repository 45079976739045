import React , {useState} from "react";
import "../styles/additems.css";
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import { useNavigate } from 'react-router-dom';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PublicSharpIcon from '@mui/icons-material/PublicSharp';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded';
import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded';
import { IconButton } from "@mui/material";
const Additems = () => {
const navigate = useNavigate();
    const [isDark, setIsDark] = useState(false); // State for dark background effect
    const [showProfileOverlay, setShowProfileOverlay] = useState(false); // State for profile overlay
    const [isOverlayVisible, setOverlayVisible] = useState(false);
  const handleGoBack = () => {
      navigate(-1);
    };
    const [isActive, setIsActive] = useState(true);

    const toggleActive = () => {
      setIsActive(!isActive);
    };
 
    const handleProfileClick = () => {
      setShowProfileOverlay(!showProfileOverlay);
      setIsDark(!isDark); // Toggle dark background when profile is clicked
  };
  const handleOverlayToggle = () => {
    setOverlayVisible(!isOverlayVisible);
  };

  const handleCancel = () => {
    if (window.confirm("Are you sure you want to cancel? All unsaved changes will be lost.")) {
      // Reset the form fields
      const form = document.getElementById("salesOrderForm");
      if (form) {
        form.reset();
      }
      // Refresh the page
      window.location.reload();
    }
  };
  return (
    <div className="additems-page">
    {/* Main Content */}
    <main className="main-content">
        <header className="additems-header">
          <h1>ITEM LIST</h1>
          <IconButton onClick={handleGoBack} color="primary" aria-label="go back">
          <ArrowCircleLeftOutlinedIcon
             className="arrowback-additems"
             fontSize="larger" /* or "small", "", "medium" */
            />
            </IconButton>
          <div className="additems-search">
            <input type="text" placeholder="Search for something" />
            <PublicSharpIcon
              className="public-additems"
              fontSize="larger" /* or "small", "", "medium" */
              
              />
              <NotificationsIcon
                className="notification-additems"
                fontSize="larger" /* or "small", "", "medium" */  
             
                aria-label="notifications"
              />
               {/* Profile Icon */}
             <img
                src="./profile.png"
              className="profile-additems"
                alt="profile"
              
                onClick={handleProfileClick}
              />
          {/* Profile Overlay */}
          {showProfileOverlay && (
                <div className="profile-overlay">
                  <div className="view-profile">
                    {/* Cancel icon now only closes the profile overlay */}
                    <CancelTwoToneIcon
                      onClick={handleGoBack}
                      sx={{
                        position: "absolute",
                        bottom: "295px",
                        right: "5px",
                        color: "red",
                        fontSize: "20px",
                        cursor: "pointer",
                      }}
                    />
                    <img src="./dp.png" alt="dp"></img>
                    <b>EZBillify</b>
                    <p>GSTIN:2964A78DL102865</p>
                    <hr/>
                 <SettingsRoundedIcon 
                  sx={{position:"relative",right:"85px",top:"55px",color:"black",fontSize:"25px"}} />
                   <ManageAccountsRoundedIcon 
                  sx={{position:"relative",right:"110px",top:"20px",color:"black",fontSize:"25px"}} />
                   <PersonAddAltRoundedIcon 
                 sx={{position:"relative",right:"132px",top:"89px",color:"black",fontSize:"25px"}} />
                  <div className="dp-heading">
                  <z  onClick={() => navigate("/Business")}>BUSINESS<c >DETAILS</c> </z>
                    <d  onClick={() => navigate("/settings")} >SETTINGS</d>
                    <e  onClick={() => navigate("/AddUsers")}>  ADD <f>USERS</f></e>
                  </div>
                  
                  </div>
                </div>
              )}
            </div>
              
        </header>

        <div className="form-container">
      <h2>New Item</h2>
     
        <div className="itemname">
          <label>ITEM NAME*</label>
          <input type="text" placeholder="Enter item name" required />
        </div>
        <div className="uom"> 
          <label>UoM*</label>
          <input type="text" placeholder="Enter UoM" />
        </div>
        <div className="mrp">
          <label>MRP</label>
          <input type="text" placeholder="Enter MRP" />
        </div>
        <div className="brand">
          <label>BRAND</label>
          <input type="text" placeholder="Enter brand" />
        </div>
        <div className="sku">
          <label>SKU / ITEM CODE*</label>
          <input type="text" placeholder="Enter SKU/Code" />
        </div>
        
        <div className="hsn">
          <label>HSN CODE/ SAC</label>
          <input type="text" placeholder="Enter HSN Code" />
        </div>
      
        <div className="sellprice-BF">
          <label>SELL PRICE (before tax)</label>
          <input type="text" placeholder="Enter sell price" />
        </div>
      
        <div className="barcode">
          <label>BARCODE</label>
          <input type="text" placeholder="Enter barcode" />
        </div>
        <div className="type">
          <label>TYPE*</label>
          <input type="text" placeholder="Enter type" />
        </div>
        <div className="taxrate">
          <label>TAX RATE*</label>
          <input type="text" placeholder="Enter tax rate" />
        </div>
        <div className="sellprice-AF">
          <label>SELL PRICE (after tax)</label>
          <input type="text" placeholder="Enter sell price" />
        </div>
        <div className="category">
          <label>CATEGORY</label>
          <input type="text" placeholder="Enter category" />
        </div>
      </div>/
      <div>
      {/* Button to trigger the overlay */}
      <button className="multiple" onClick={handleOverlayToggle}>+ ADD MULTIPLE MRP</button>

    
        {/* Overlay */}
        {isOverlayVisible && (
          <div className="overlay">
            <div className="overlay-content">
              <ArrowCircleLeftOutlinedIcon
                className="back-icon"
                fontSize="large"
                onClick={handleOverlayToggle}
              />
              <h2 >Add Multiple MRP</h2>
              <form>
              <div className="container">
      {[2, 3, 4].map((num) => (
        <div className="row" key={num}>
          <div className="input-group">
            <label htmlFor={`mrp${num}`}>MRP {num}</label>
            <input type="text" id={`mrp${num}`} name={`mrp${num}`} />
          </div>
          <div className="input-group">
            <label htmlFor={`sellBeforeTax${num}`}>SELL PRICE (before tax) {num}</label>
            <input type="text" id={`sellBeforeTax${num}`} name={`sellBeforeTax${num}`} />
          </div>
          <div className="input-group">
            <label htmlFor={`sellAfterTax${num}`}>SELL PRICE (after tax) {num}</label>
            <input type="text" id={`sellAfterTax${num}`} name={`sellAfterTax${num}`} />
          </div>
        </div>
      ))}
    </div>
              </form>
              <button type="submit" className="submit-button">Submit</button>
           
            </div>
          </div>
        )}
    </div>

      <div className="toggle-container">
       
        <div className={`toggle ${isActive ? 'on' : 'off'}`} onClick={toggleActive}>
          <div className="circle"></div>
        </div>
      </div>

      <div className="button-container">
        <button className="save-button">Save</button>
        <button  onClick={handleCancel} className="cancel-button">Cancel</button>
      </div>
   

         
      </main>
    </div>
    
    
  );
};

export default Additems;
