import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/dashboard.css"; // Link your custom styles
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2"; // For charts
import NotificationsIcon from "@mui/icons-material/Notifications";
import PublicSharpIcon from "@mui/icons-material/PublicSharp";
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded';
import AppShortcutOutlinedIcon from '@mui/icons-material/AppShortcutOutlined';

// Register the necessary components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const Dashboard = () => {
  const navigate = useNavigate();
  const [showProfileOverlay, setShowProfileOverlay] = useState(false); // State for profile overlay
  const [isDark, setIsDark] = useState(false); // State for dark background effect
  const [searchQuery, setSearchQuery] = useState(""); // State for search query
  const [suggestions, setSuggestions] = useState([]); // State for search suggestions
  const [showShortcuts, setShowShortcuts] = useState(false); // State for shortcuts overlay

  // List of possible pages/routes
  const pages = [
    "dashboard",
    "sales",
    "items",
    "purchase",
    "masterdata",
    "accounting",
    "gstreturns",
    "others",
    "settings",
    "business-details",
  ];

  // Chart Data
  const chartData = {
    labels: ["2014", "2015", "2016", "2017", "2018", "2019", "2020"],
    datasets: [
      {
        label: "CT Osprey Nest Count",
        data: [400, 450, 500, 600, 650, 700, 800],
        backgroundColor: "rgba(54, 162, 235, 0.6)",
      },
    ],
  };
  // Add shortcut key functionality for Alt and Ctrl
  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check if either Alt or Ctrl is pressed
      if (event.altKey || event.ctrlKey) {
        switch (event.key.toLowerCase()) {
          case "c":
            navigate("/customers");
            break;
          case "a":
            navigate("/addcustomer");
            break;
          case "p":
            navigate("/prints");
            break;
          case "e":
            navigate("/addemployee");
            break;
          default:
            break;
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [navigate]);
  const handleProfileClick = () => {
    setShowProfileOverlay(!showProfileOverlay);
    setIsDark(!isDark); // Toggle dark background when profile is clicked
  };

  const handleGoBack = () => {
    setShowProfileOverlay(false); // Just close the profile overlay
    setIsDark(false); // Optionally reset dark background
  };

  // Handle search input change
  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    // Filter pages based on the search query
    if (query.trim() === "") {
      setSuggestions([]); // Clear suggestions if the input is empty
    } else {
      const filteredSuggestions = pages.filter((page) =>
        page.toLowerCase().startsWith(query.toLowerCase())
      );
      setSuggestions(filteredSuggestions);
    }
  };

  // Handle search on Enter key press
  const handleSearch = (event) => {
    if (event.key === "Enter" && searchQuery.trim()) {
      switch (searchQuery.toLowerCase()) {
        case "sales":
          navigate("/sales");
          break;
        case "dashboard":
          navigate("/dashboard");
          break;
        case "purchase":
          navigate("/purchase");
          break;
        case "accounting":
          navigate("/accounting");
          break;
        case "gstreturns":
          navigate("/gstreturns");
          break;
        case "settings":
          navigate("/settings");
          break;
        case "items":
          navigate("/items");
          break;
        case "masterdata":
          navigate("/masterdata");
          break;
        case "business-details":
          navigate("/business-details");
          break;
        case "others":
          navigate("/others");
          break;
        default:
          alert("Page not found!");
          break;
      }
    }
  };

  return (
    <body>
    <div className={`dashboard-container ${isDark ? "dark-background" : ""}`}>
      {/* Main Content */}
      <main className="main-content">
        <header className="dashboard-header">
          <h1>DASHBOARD</h1>
          <div className="header-search">
            <input
              type="text"
              placeholder="Search for something"
              value={searchQuery}
              onChange={handleSearchChange} // Track search input
              onKeyDown={handleSearch} // Trigger search on Enter key press
            />
            {/* Show suggestions if there are any */}
            {suggestions.length > 0 && (
              <div className="suggestions-dropdown">
                {suggestions.map((suggestion, index) => (
                  <div
                    key={index}
                    className="suggestion-item"
                    onClick={() => {
                      navigate(`/${suggestion}`);
                      setSearchQuery(""); // Clear search input after selection
                      setSuggestions([]); // Clear suggestions
                    }}
                  >
                    {suggestion}
                  </div>
                ))}
              </div>
            ) }
            
            <AppShortcutOutlinedIcon className="shortcut" onClick={() => setShowShortcuts(true)}   fontSize="larger" /* or "small", "", "medium" *//>
            {showShortcuts && (
              <div className="shortcuts-overlay ">
                <div className="overlay-content">
                  <h2>Shortcut Keys</h2>
                  <ul>
                    <li><strong>Alt + C</strong>: Go to Customer Page</li>
                    <li><strong>Alt + A</strong>: Go to Add Customer Page</li>
                    <li><strong>Alt + P</strong>: Go to Print Page</li>
                    <li><strong>Alt + E</strong>: Go to Add Employee Page</li>
                    <li><strong>Ctrl + C</strong>:  Go to Customer Page</li>
                    <h4>For Mac </h4>
                    <li><strong>Ctrl + A</strong>: Go to Add Customer Page</li>
                    <li><strong>Ctrl + P</strong>: Go to Print Page</li>
                    <li><strong>Ctrl + E</strong>: Go to Add Employee Page</li>
                  </ul>
                  <CancelTwoToneIcon onClick={() => setShowShortcuts(false)}className="cancle-shortcut" fontSize="larger" /* or "small", "", "medium" *//> 
                </div>
              </div>
            )}

            <div className="icons">
              <PublicSharpIcon
              className="public"
              fontSize="larger" /* or "small", "", "medium" */
              
              />
              <NotificationsIcon
                className="notification"
                fontSize="larger" /* or "small", "", "medium" */  
             
                aria-label="notifications"
              />
             {/* Profile Icon */}
             <img
                src="./profile.png"
              className="profile"
                alt="profile"
              
                onClick={handleProfileClick}
              />
              {/* Profile Overlay */}
              {showProfileOverlay && (
               
                <div className="profile-overlay " >
                  <div className="view-profile">
                    {/* Cancel icon now only closes the profile overlay */}
                    <CancelTwoToneIcon
                      onClick={handleGoBack}
                      sx={{
                        position: "absolute",
                        bottom: "295px",
                        right: "5px",
                        color: "red",
                        fontSize: "20px",
                        cursor: "pointer",
                      }}
                    />
                    <img src="./dp.png" alt="dp"></img>
                    <b>EZBillify</b>
                    <p>GSTIN:2964A78DL102865</p>
                    <hr/>
                 <SettingsRoundedIcon 
                  sx={{position:"relative",right:"85px",top:"55px",color:"black",fontSize:"25px"}} />
                   <ManageAccountsRoundedIcon 
                  sx={{position:"relative",right:"110px",top:"20px",color:"black",fontSize:"25px"}} />
                   <PersonAddAltRoundedIcon 
                 sx={{position:"relative",right:"132px",top:"89px",color:"black",fontSize:"25px"}} />
                  <div className="dp-heading">
                    <z  onClick={() => navigate("/business-details")}>BUSINESS<c >DETAILS</c> </z>
                    <d  onClick={() => navigate("/settings")} >SETTINGS</d>
                    <e  onClick={() => navigate("/AddUsers")}>  ADD <f>USERS</f></e>
                  </div>
                  
                  </div>
                </div>
              )}
            </div>
          </div>
        </header>

        {/* Buttons */}
        <section className="functional1">
          
            <img
              src="./Invoice.png"
              alt="Robot"
              onClick={() => navigate("/invoice")} 
            />
      
        </section>

        {/* Stats */}
        <section className="stats">
          <div className="stat">
            <h3>Low on Stock</h3>
            <p>5 Items</p>
          </div>
          <div className="stat">
            <h3>Total Sales</h3>
            <p>2,00,000</p>
          </div>
          <div className="stat">
            <h3>Total Purchase</h3>
            <p>1,00,000</p>
          </div>
        </section>
        <section className="statdown">
          <div className="down">
            <h3 onClick={() => navigate("/addcustomer")}>ADD CUSTOMER</h3>
          </div>
          <div className="down">
            <h3>ADD EMPLOYEE</h3>
          </div>
          <div className="down">
            <h3>PRINTS</h3>
          </div>
        </section>

        {/* Charts */}
        <section className="charts">
          <div className="chart">
            <div className="bar-sales">
              <h3>Sales Trend</h3>
              <Bar className="chart1" data={chartData} />
            </div>
          </div>
          <div className="chart">
            <div className="bar-purchase">
              <h3>Purchase Trend</h3>
              <Bar className="chart2" data={chartData} />
            </div>
          </div>
        </section>
      </main>
    </div>
    </body>
  );
};

export default Dashboard;
