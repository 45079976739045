import React, { useState } from "react";
import { storeUserDetails, redirectToGoogleForm } from "../firebase"; // Import Firebase functions
import { useNavigate } from "react-router-dom"; // Import navigation hook
import "./../styles/Register.css";

const ContactPage = () => {
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showPopup, setShowPopup] = useState(false);

  const navigate = useNavigate(); // Initialize navigate

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!name || !address || !phoneNumber) {
      setErrorMessage("Please fill out all fields.");
      return;
    }

    // Store user details in Firestore
    await storeUserDetails(name, address, phoneNumber);

    // Show the popup message
    setShowPopup(true);

    // Redirect to Google Form
    redirectToGoogleForm();
  };

  // Handle close popup and redirect to login page
  const handleClosePopup = () => {
    setShowPopup(false);
    navigate("/login"); // Redirect to login page
  };

  return (
    <div className="contact-page">
      <h1>Contact Us</h1>
      <form onSubmit={handleSubmit} className="contact-form">
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            placeholder="Enter your name"
          />
        </div>

        <div className="form-group">
          <label htmlFor="address">Address</label>
          <input
            type="text"
            id="address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            required
            placeholder="Enter your address"
          />
        </div>

        <div className="form-group">
          <label htmlFor="phoneNumber">Phone Number</label>
          <input
            type="text"
            id="phoneNumber"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            required
            placeholder="Enter your phone number"
          />
        </div>

        {errorMessage && <p className="error-message">{errorMessage}</p>}

        <button type="submit" className="submit-btn">Submit</button>
      </form>

      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <p>Thank you! You are successfully registered.</p>
            <p>We will get back to you within 24 - 48 hours.</p>
            <button onClick={handleClosePopup}>Close</button>
          </div>
        </div>
      )}

      <div className="google-form-link"></div>
    </div>
  );
};

export default ContactPage;
