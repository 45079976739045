import React, { useState, useEffect } from "react";
import { auth } from "../firebase";
import { signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import "../styles/Login.css"; // You can use this for additional custom styling

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        navigate("/dashboard"); // Redirect to dashboard if logged in
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate("/dashboard");
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <body>
    <div className="login-page">
      <div className="login-container">
        {/* Left Section: Login Form */}
        <div className="login-form ">
          <div className="logo">
            <img
              className="logo1"
              src="./logo.png"
              width="200px"
              height="40px"
              alt="logo"
            />
          </div>
          <h2 className="text-primary">WELCOME TO EZBILLIFY</h2>
          <p className="text-muted">Your Admin Panel</p>
          <form onSubmit={handleLogin}>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Username
              </label>
              <input
                type="email"
                id="email"
                className="form-control"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="password" className="form-label">
                Password
              </label>
              <input
                type="password"
                id="password"
                className="form-control"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div className="form">
              <label htmlFor="remember" className="check">
                <input
                  id="remember"
                  type="checkbox"
                  className="form-check-input "
                  value="Remember this Device"
                />
                Remember this Device
              </label>
              <a href="/forgot-password" className="forgot">
                Forgot Password?
              </a>
            </div>
            <button type="submit" className="login-btn">
              Sign In
            </button>
          </form>
          <p className="create-account">
            New to Ezbillify?{" "}
            <a href="/register" className="text-primary">
              Contact Us
            </a>
          </p>
        </div>

        {/* Right Section: Illustration */}
        <div className="login-illustration">
          <img
            src="./Robot.png"
            alt="Illustration"
            style={{ maxWidth: "110%" }}
          />
        </div>
      </div>
    </div>
    </body>
  );
};

export default Login;
