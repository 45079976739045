import React, { useState } from "react";
import "../styles/bulkupload.css";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import { useNavigate } from "react-router-dom";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PublicSharpIcon from "@mui/icons-material/PublicSharp";

import { IconButton } from "@mui/material";
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded';
import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded';

const Bulkupload = () => {
  const navigate = useNavigate();
  const [fileName, setFileName] = useState("");
  const [fileUploaded, setFileUploaded] = useState(false);
  const [isDark, setIsDark] = useState(false); // State for dark background effect
  const [showProfileOverlay, setShowProfileOverlay] = useState(false); // State for profile overlay
  const handleGoBack = () => {
    navigate("/sales"); // Navigates to the previous page in the history stack
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
      setFileUploaded(true);
    }
  };
  const handleProfileClick = () => {
    setShowProfileOverlay(!showProfileOverlay);
    setIsDark(!isDark); // Toggle dark background when profile is clicked
  };
  const removeFile = () => {
    setFileName("");
    setFileUploaded(false);
  };

  return (
    <div className="bulk-page">
      {/* Main Content */}
      <main className="main-content">
        {/* Header */}
        <header className="bulk-header">
          <h1>Customer - Bulk Upload</h1>
          <IconButton onClick={handleGoBack} color="white" aria-label="go back">
          <ArrowCircleLeftOutlinedIcon
             className="arrowback-bulk"
             fontSize="larger" /* or "small", "", "medium" */
            />
          </IconButton>
          <div className="bulk-search">
            <input type="text" placeholder="Search for something" />
            <PublicSharpIcon
              className="public-bulk"
              fontSize="larger" /* or "small", "", "medium" */
              
              />
              <NotificationsIcon
                className="notification-bulk"
                fontSize="larger" /* or "small", "", "medium" */  
             
                aria-label="notifications"
              />
               {/* Profile Icon */}
             <img
                src="./profile.png"
              className="profile-bulk"
                alt="profile"
              
                onClick={handleProfileClick}
              />
          {/* Profile Overlay */}
          {showProfileOverlay && (
                <div className="profile-overlay">
                  <div className="view-profile">
                    {/* Cancel icon now only closes the profile overlay */}
                    <CancelTwoToneIcon
                      onClick={handleGoBack}
                      sx={{
                        position: "absolute",
                        bottom: "295px",
                        right: "5px",
                        color: "red",
                        fontSize: "20px",
                        cursor: "pointer",
                      }}
                    />
                    <img src="./dp.png" alt="dp"></img>
                    <b>EZBillify</b>
                    <p>GSTIN:2964A78DL102865</p>
                    <hr/>
                 <SettingsRoundedIcon 
                  sx={{position:"relative",right:"85px",top:"55px",color:"black",fontSize:"25px"}} />
                   <ManageAccountsRoundedIcon 
                  sx={{position:"relative",right:"110px",top:"20px",color:"black",fontSize:"25px"}} />
                   <PersonAddAltRoundedIcon 
                 sx={{position:"relative",right:"132px",top:"89px",color:"black",fontSize:"25px"}} />
                  <div className="dp-heading">
                    <a  onClick={() => navigate("/Business")}>BUSINESS<c >DETAILS</c> </a>
                    <d  onClick={() => navigate("/settings")} >SETTINGS</d>
                    <e  onClick={() => navigate("/AddUsers")}>  ADD <f>USERS</f></e>
                  </div>
                  
                  </div>
                </div>
              )}
              </div>
        </header>

        {/* Three-Step Process */}
        <header className="step-header">
        <section className="steps-section" style={{ marginTop: "50px", textAlign: "center" }}>
          <div style={{ position:"relative",width:"850px",left:"100px",bottom:"40px", display: "flex", justifyContent: "space-around", alignItems: "center" }}>
            {/* Step 1 */}
            <div>
              <div style={{ fontSize: "25px", fontWeight: "bold" }}>Step 1</div>
              <button style={buttonStyle}>Download</button>
            </div>

            {/* Divider */}
            <div style={{ flex: 1, height: "2px", background: "black", margin: "0 20px" }}></div>

            {/* Step 2 */}
            <div>
              <div style={{ fontSize: "25px", fontWeight: "bold" }}>Step 2</div>
              <button style={buttonStyle}>Choose</button>
            </div>

            {/* Divider */}
            <div style={{ flex: 1, height: "2px", background: "black", margin: "0 20px" }}></div>

            {/* Step 3 */}
            <div>
              <div style={{ fontSize: "25px", fontWeight: "bold" }}>Step 3</div>
              <button style={buttonStyle}>Upload</button>
            </div>
          </div>
          
        </section>
        </header>

        {/* File Upload Section */}
        <section className="file-upload-section">
        
          <h2 style={{ marginBottom: "20px" }}>Upload File</h2>
          <input
            type="file"
            onChange={handleFileChange}
            style={{
              display: "block",
              marginBottom: "20px",
              padding: "10px",
              width: "75%",
              border: "1px solid #ccc",
              borderRadius: "5px",
            }}
          />
          {fileUploaded && (
            <div style={{ marginBottom: "20px" }}>
              <p>
                <strong>File Name:</strong> {fileName}
              </p>
            </div>
          )}
          <div>
            <button
              style={{ ...buttonStyle, marginRight: "10px" }}
              disabled={!fileUploaded}
            >
              Upload
            </button>
            {fileUploaded && (
              <button
                style={{
                  ...buttonStyle,
                  backgroundColor: "red",
                  color: "white",
                }}
                onClick={removeFile}
              >
                Remove File
              </button>
            )}
          </div>
        </section>
      </main>
    </div>
  );
};

const buttonStyle = {
  padding: "10px 20px",
  fontSize: "16px",
  backgroundColor: "#5BC6CC",
  color: "white",
  border: "none",
  borderRadius: "5px",
  cursor: "pointer",
};

export default Bulkupload;
