import React,{useState} from "react";
import "../styles/invoice.css";
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import { useNavigate } from 'react-router-dom';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PublicSharpIcon from '@mui/icons-material/PublicSharp';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded';
import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded';
import { IconButton, Button } from "@mui/material";
const Invoice = () => {
  const navigate = useNavigate();
  const [isDark, setIsDark] = useState(false); // State for dark background effect
  const [showProfileOverlay, setShowProfileOverlay] = useState(false); // State for profile overlay
const handleGoBack = () => {
    navigate("/sales");
  };

  const handleSort = () => {
    navigate("/sort");
  };

  const handleFilter = () => {
    navigate("/filter");
  };

  const handleNewInvoice = () => {
    navigate('/newinvoice'); // Navigates to the previous page in the history stack
  };
  const handleProfileClick = () => {
    setShowProfileOverlay(!showProfileOverlay);
    setIsDark(!isDark); // Toggle dark background when profile is clicked
  };
  return (
    <div className="invoice-page">
    {/* Main Content */}
    <main className="main-content">
        <header className="invoice-header">
          <h1>INVOICE</h1>
          <IconButton onClick={handleGoBack} color="primary" aria-label="go back">
          <ArrowCircleLeftOutlinedIcon
             className="arrowback-invoice"
             fontSize="larger" /* or "small", "", "medium" */
            />
            </IconButton>
          <div className="invoice-search">
            <input type="text" placeholder="Search for something" />
            <PublicSharpIcon
              className="public-invoice"
              fontSize="larger" /* or "small", "", "medium" */
              
              />
              <NotificationsIcon
                className="notification-invoice"
                fontSize="larger" /* or "small", "", "medium" */  
             
                aria-label="notifications"
              />
               {/* Profile Icon */}
             <img
                src="./profile.png"
              className="profile-invoice"
                alt="profile"
              
                onClick={handleProfileClick}
              />
          {/* Profile Overlay */}
          {showProfileOverlay && (
                <div className="profile-overlay">
                  <div className="view-profile">
                    {/* Cancel icon now only closes the profile overlay */}
                    <CancelTwoToneIcon
                      onClick={handleGoBack}
                      sx={{
                        position: "absolute",
                        bottom: "295px",
                        right: "5px",
                        color: "red",
                        fontSize: "20px",
                        cursor: "pointer",
                      }}
                    />
                    <img src="./dp.png" alt="dp"></img>
                    <b>EZBillify</b>
                    <p>GSTIN:2964A78DL102865</p>
                    <hr/>
                 <SettingsRoundedIcon 
                  sx={{position:"relative",right:"85px",top:"55px",color:"black",fontSize:"25px"}} />
                   <ManageAccountsRoundedIcon 
                  sx={{position:"relative",right:"110px",top:"20px",color:"black",fontSize:"25px"}} />
                   <PersonAddAltRoundedIcon 
                 sx={{position:"relative",right:"132px",top:"89px",color:"black",fontSize:"25px"}} />
                  <div className="dp-heading">
                    <z  onClick={() => navigate("/Business")}>BUSINESS<c >DETAILS</c> </z>
                    <d  onClick={() => navigate("/settings")} >SETTINGS</d>
                    <e  onClick={() => navigate("/AddUsers")}>  ADD <f>USERS</f></e>
                  </div>
                  
                  </div>
                </div>
              )}
            </div>
              
        </header>

        <div className="button-section" style={{ marginTop: "25px", marginLeft: "60%", textAlign: "center" }}>
          <Button
            variant="contained"
            sx={{
              position: "absolute",
              right: "1580px",
              top:"170px",
              width:"250px",
              height:"50px",
              backgroundColor: "white",
              color: "black",
              borderRadius: "10px",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "white",
                color: "black",
                boxShadow: "none",
              },
            }}
            onClick={handleSort}
          >
            Sort
          </Button>
          <Button
            variant="contained"
            sx={{
              position: "absolute",
              right: "1300px",
              top:"170px",
              width:"250px",
              height:"50px",
              backgroundColor: "white",
              color: "black",
              borderRadius: "10px",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "white",
                color: "black",
                boxShadow: "none",
              },
            }}
            onClick={handleFilter}
          >
           Filter
          </Button>
          <Button
            variant="contained"
            onClick={handleNewInvoice}
            sx={{
              position: "absolute",
              right: "65px",
              top:"170px",
              width:"250px",
              height:"50px",
              backgroundColor: "white",
              color: "black",
              borderRadius: "10px",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "white",
                color: "black",
                boxShadow: "none",
              },
            }}
          >
             <b>+</b> New Invoice
          </Button>
        </div>
        
        <div className="invoice-list" >
          <table className="invoice-table">
            <thead>
              <tr>
                <th>Customer Name</th>
                <th>GSTIN</th>
                <th>Invoice No</th>
                <th>Date</th>
                <th>Invoice Total</th>
                <th>Received</th>
                <th>Returns</th>
                <th>Due Amt</th>
              </tr>
            </thead>
       </table>
       </div>
         
      </main>
    </div>
    
  );
};

export default Invoice;
