import React, { useState } from "react";
import "../styles/newinvoice.css";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import { useNavigate } from "react-router-dom";
import { Button} from "@mui/material";
import { getDatabase, ref, set, push } from "firebase/database"; // Import Realtime Database methods
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import { savePaymentType } from "../firebase";

const Newinvoice = () => {
  const navigate = useNavigate();
  const db = getDatabase(); // Initialize Realtime Database instance
const [paymentType, setPaymentType] = useState(""); // For storing the selected payment type
const [details] = useState({}); // Replace with your actual details object
  const [invoice, setInvoice] = useState({
    customerName: "",
    invoiceDate: "",
    dueDate: "",
    poDate:"",
    deliveryDate:"",
    invoiceNumber:"",
    transportDetails:"",
    placeofSupply:"",
    paymentMethod:"",
    pob:"",
    items: [],
    subTotal: 0,
    sgst: 0,
    cgst: 0,
    totalAmount: 0,
  });

const handleSave = async () => {
    try {
      await savePaymentType(paymentType, details);
      alert("Payment type saved successfully!");
    } catch (error) {
      console.error("Error saving payment type:", error);
    }
    navigate('/invoice');
  };

  const handleGoBack = () => {
    navigate(-1); // Navigates to the previous page in the history stack
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInvoice((prev) => ({
      ...prev,
      [name]: value,
    }));
   
  };

  const addInvoiceToDatabase = async () => {
    try {
      const invoiceRef = ref(db, "invoices"); // Reference to 'invoices' collection
      const newInvoiceRef = push(invoiceRef); // Generate a new unique key
      await set(newInvoiceRef, invoice); // Save the invoice data
      alert("Invoice saved successfully!");
    } catch (error) {
      console.error("Error saving invoice:", error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    addInvoiceToDatabase();
  };

  
  const handlePrint = () => {
    navigate('/newinvoice'); // Navigates to the previous page in the history stack
  };
  const handleEinvoice = () => {
    navigate('/einvoice'); // Navigates to the previous page in the history stack
  };
  const handleEwaybill = () => {
    navigate('/ewaybill'); // Navigates to the previous page in the history stack
  };
  const handleNewInvoice = () => {
    navigate('/ewaybill'); // Navigates to the previous page in the history stack
  };

  const handleCancel = () => {
    if (window.confirm("Are you sure you want to cancel? All unsaved changes will be lost.")) {
      // Reset the form fields
      const form = document.getElementById("salesOrderForm");
      if (form) {
        form.reset();
      }
      // Refresh the page
      window.location.reload();
    }
  };

  return (
    <body>
    <div className="newinvoice-page">
   
    <ArrowCircleLeftOutlinedIcon
    onClick={handleGoBack}
             className="arrowback-newinvoice"
             fontSize="larger" /* or "small", "", "medium" */
            />

        {/* Invoice Form */}
        
        <form onSubmit={handleSubmit} className="invoice-form">
          <div>
          <div className="header1" >
            <input
            placeholder="Customer Name"
              type="text"
              name="customerName"
              value={invoice.customerName}
              onChange={handleInputChange}
              required
            />
           <ReceiptLongOutlinedIcon  className="receipt-newinvoice"
             fontSize="larger" /* or "small", "", "medium" *//> <b>Pending Invoices</b>
           <hr class="vertical-line1" />
           <c>View Customer Details</c>
           <u>Billing Address</u>
          </div></div>
          <div className="header2">
          <div className="radio">
      
      <label className="radio1">
        <input
        className="radioinput"
          type="radio"
          name="paymentType"
          value="cash"
          onChange={(e) => setPaymentType(e.target.value)}
          required
        />
        <c>Cash</c>
      </label>
      <label className="radio2">
        <input
        className="radioinput1"
          type="radio"
          name="paymentType"
          value="credit"
          onChange={(e) => setPaymentType(e.target.value)}
          req
        />
       <c>Credit</c> 
      </label>
  
    </div>
            
         <label className="invoicedate1" >Invoice Date</label>
            <input
            className="date"
              type="date"
              name="invoiceDate"
              value={invoice.invoiceDate}
              onChange={handleInputChange}
              required
            />
            <label className="duedate1">Due Date</label>
            <input
            className="duedate"
              type="date"
              name="dueDate"
              value={invoice.dueDate}
              onChange={handleInputChange}
            />
              <label className="podate1">P.O Date</label>
            <input
            className="podate"
              type="date"
              name="poDate"
              value={invoice.poDate}
              onChange={handleInputChange}
            />
              <label className="deliverydate1">Delivery Date</label>
            <input
            className="deliverydate"
              type="date"
              name="deliveryDate"
              value={invoice.deliveryDate}
              onChange={handleInputChange}
            />
              <label className="transportdetails1">Transport Details</label>
            <input
            className="transportdetails"
              type="text"
              name="transportDetails"
              value={invoice.transportDetails}
              onChange={handleInputChange}
            />
            
            <label className="placeofsupply1">Place of Supply</label>
            <input
            className="placeofsupply"
              type="text"
              name="placeofSupply"
              value={invoice.placeofSupply}
              onChange={handleInputChange}
            />
               <label className="invoicenumber1">Invoice Number</label>
            <input
            className="invoicenumber"
              type="text"
              name="invoiceNumber"
              value={invoice.invoiceNumber}
              onChange={handleInputChange}
            />
           
             <label className="pob1">P.O #</label>
            <input
            className="pob"
              type="text"
              name="pob"
              value={invoice.pob}
              onChange={handleInputChange}
            />
          
          </div>
          <div className="buttons1">
          <Button
            variant="contained"
            onClick={handleNewInvoice}
            sx={{
              position: "absolute",
              right: "45px",
              backgroundColor: "white",
              top:"20px",
              color: "black",
              width:"14%",
              height:"80px",
              borderRadius: "10px",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "white",
                color: "black",
                boxShadow: "none",
              },
            }}
          >
            <b > Payment</b>
          </Button>
          <Button
            variant="contained"
            onClick={handlePrint}
            sx={{
              position: "absolute",
              right: "45px",
              backgroundColor: "white",
              top:"110px",
              color: "black",
              width:"14%",
              height:"80px",
              borderRadius: "10px",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "white",
                color: "black",
                boxShadow: "none",
              },
            }}
          >
            <b>Print</b>
          </Button>
          <Button
            variant="contained"
            onClick={handleNewInvoice}
            sx={{
              position: "absolute",
              right: "45px",
              backgroundColor: "white",
              top:"200px",
              color: "black",
              width:"14%",
              height:"80px",
              borderRadius: "10px",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "white",
                color: "black",
                boxShadow: "none",
              },
            }}
          >
            <b>Share</b>
          </Button>
          <Button
            variant="contained"
            onClick={handleEinvoice}
            sx={{
              position: "absolute",
              right: "45px",
              backgroundColor: "white",
              top:"290px",
              color: "black",
              width:"14%",
              height:"80px",
              borderRadius: "10px",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "white",
                color: "black",
                boxShadow: "none",
              },
            }}
          >
            <b>E-Invoice</b>
          </Button>
          <Button
            variant="contained"
            onClick={handleEwaybill}
            sx={{
              position: "absolute",
              right: "45px",
              backgroundColor: "white",
              top:"380px",
              color: "black",
              width:"14%",
              height:"80px",
              borderRadius: "10px",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "white",
                color: "black",
                boxShadow: "none",
              },
            }}
          >
            <b>E-Way Bill</b>
          </Button>
          <Button
            variant="contained"
            onClick={handleNewInvoice}
            sx={{
              position: "absolute",
              right: "45px",
              backgroundColor: "white",
              top:"470px",
              color: "black",
              width:"14%",
              height:"80px",
              borderRadius: "10px",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "white",
                color: "black",
                boxShadow: "none",
              },
            }}
          >
            <b>Returns</b>
          </Button>
          </div>


<div className="totals">
  <p>Sub Total: <span>{parseFloat(invoice.subTotal).toFixed(2)}</span></p><hr/>
  <p>SGST: <span>{parseFloat(invoice.sgst).toFixed(2)}</span></p><hr/>
  <p>CGST: <span>{parseFloat(invoice.cgst).toFixed(2)}</span></p><hr/>
  <p>ROUND OFF: <span>{parseFloat(invoice.cgst).toFixed(2)}</span></p><hr/>
  <p>Total Amount: <span>{parseFloat(invoice.totalAmount).toFixed(2)}</span></p>
  <hr class="vertical-line2" />
</div>
<div className="btn-down">
          <Button
          type="submit"
            variant="contained"
            onClick={handleSave}
            sx={{
              position: "absolute",
              left: "80px",
              backgroundColor: "white",
              bottom:"145px",
              color: "black",
              width:"14%",
              height:"80px",
              borderRadius: "10px",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "white",
                color: "black",
                boxShadow: "none",
              },
            }}

          >
            <b>Save Invoice </b>
          </Button>
          <Button
         
            variant="contained"
       
            sx={{
              position: "absolute",
              left: "80px",
              backgroundColor: "white",
              bottom:"260px",
              color: "black",
              width:"14%",
              height:"80px",
              borderRadius: "10px",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "white",
                color: "black",
                boxShadow: "none",
              },
            }}

          >
            <b> + ADD ITEM </b>
          </Button>
          <Button
        
            variant="contained"
           
            sx={{
              position: "absolute",
              left: "80px",
              backgroundColor: "white",
              bottom:"30PX",
              color: "black",
              width:"14%",
              height:"80px",
              borderRadius: "10px",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "white",
                color: "black",
                boxShadow: "none",
              },
            }}

          >
            <b>SETTINGS </b>
          </Button>
          <Button
        
            variant="contained"
           
            sx={{
              position: "absolute",
              left: "500px",
              backgroundColor: "white",
              bottom:"260px",
              color: "black",
              width:"14%",
              height:"80px",
              borderRadius: "10px",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "white",
                color: "black",
                boxShadow: "none",
              },
            }}

          >
            <b>SCAN BARCODE </b>
          </Button>
          <Button
          type="submit"
            variant="contained"
            onClick={handleSave}
            sx={{
              position: "absolute",
              left: "500px",
              backgroundColor: "white",
              bottom:"145px",
              color: "black",
              width:"14%",
              height:"80px",
              borderRadius: "10px",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "white",
                color: "black",
                boxShadow: "none",
              },
            }}

          >
            <b>SAVE & PRINT </b>
          </Button>
          <Button
      
            variant="contained"
           
            sx={{
              position: "absolute",
              left: "500px",
              backgroundColor: "white",
              bottom:"30px",
              color: "black",
              width:"14%",
              height:"80px",
              borderRadius: "10px",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "white",
                color: "black",
                boxShadow: "none",
              },
            }}

          >
            <b>CUSTOMER DISPLAY </b>
          </Button>
          <Button
      
      variant="contained"
     onClick={handleCancel}
      sx={{
        position: "absolute",
        left: "900px",
        backgroundColor: "white",
        bottom:"30px",
        color: "black",
        width:"14%",
        height:"80px",
        borderRadius: "10px",
        boxShadow: "none",
        "&:hover": {
          backgroundColor: "white",
          color: "black",
          boxShadow: "none",
        },
      }}

    >
      <b>CANCEL </b>
      </Button>
        </div>
        </form>
       
        
    </div>
    </body>
  );
};

export default Newinvoice;
