import React from "react";
import "../styles/masterdata.css";
const Masterdata = () => {
  return (
    <body>
    <div className="masterdata-page">
      <h1>Master-Data Page^</h1>
      <br></br>
      <p >Upcomming Masterdata-Section will update here.</p>
    </div>
   </body> 
  );
};

export default Masterdata;
