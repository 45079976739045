// firebase.js

import { initializeApp } from "firebase/app";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth"; // Authentication imports
import { getFirestore, collection, addDoc, serverTimestamp, query, where, getDocs } from "firebase/firestore";
import { getDatabase, ref, set, push, onValue } from "firebase/database"; // Add Realtime Database imports

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDpvg5xxdU4dUWWmkGYeE1nlUjQF7Xrm20", // Using environment variables for sensitive keys
  authDomain: "ez-01-b4c83.firebaseapp.com",
  projectId: "ez-01-b4c83",
  storageBucket: "ez-01-b4c83.appspot.com",
  messagingSenderId: "165725948563",
  appId: "1:165725948563:web:bcbf11255b155a6a181b66",
};

// Initialize Firebase app
const app = initializeApp(firebaseConfig);

// Firebase services
export const auth = getAuth(app);
export const db = getFirestore(app);
export const database = getDatabase(app); // Add Realtime Database initialization

/**
 * Function to store user contact details in the "users" collection.
 * @param {string} name - The user's name.
 * @param {string} address - The user's address.
 * @param {string} phoneNumber - The user's phone number.
 * @returns {Promise<void>}
 /**
 * Function to save payment type (cash or credit) to Firebase Realtime Database.
 * @param {string} paymentType - The payment type selected by the user ('cash' or 'credit').
 * @param {Object} details - The details to store under the selected payment type.
 * @returns {Promise<void>}
 */
 export const savePaymentType = async (paymentType, details) => {
  try {
    // Validate the payment type
    if (paymentType !== "cash" && paymentType !== "credit") {
      throw new Error("Invalid payment type. Must be 'cash' or 'credit'.");
    }

    const dbPath = `${paymentType}`; // Dynamic path based on payment type ('cash' or 'credit')
    const dbRef = ref(database, dbPath); // Reference to the specific payment type path
    const newRecordRef = push(dbRef); // Create a new record under the payment type
    await set(newRecordRef, details); // Save details to the database
    console.log(`Payment type '${paymentType}' details saved successfully:`, details);
  } catch (error) {
    console.error("Error saving payment type to database:", error);
    throw error;
  }
};
export const storeUserDetails = async (name, address, phoneNumber) => {
  try {
    // Check if a user with the same phone number already exists
    const q = query(collection(db, "users"), where("phoneNumber", "==", phoneNumber));
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      console.error("User with this phone number already exists.");
      return;
    }
    
    await addDoc(collection(db, "users"), {
      name,
      address,
      phoneNumber,
      createdAt: new Date(),
    });
    console.log("User details stored successfully!");
  } catch (error) {
    console.error("Error adding user document:", error);
    throw error;  // Throwing the error so the caller function can handle it
  }
};

/**
 * Function to register a new user using Firebase Authentication.
 * @param {string} email - The user's email.
 * @param {string} password - The user's password.
 * @returns {Promise<void>}
 */
export const registerUser = async (email, password) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;
    console.log("User registered successfully:", user);
  } catch (error) {
    console.error("Error registering user:", error.message);
    throw error;
  }
};

/**
 * Function to store customer details in the "customers" collection.
 * @param {Object} customerData - An object containing customer details.
 * @returns {Promise<void>}
 */
export const addCustomer = async (customerData) => {
  try {
    // Add a new document with a generated ID in the "customers" collection
    await addDoc(collection(db, "customers"), {
      ...customerData,
      createdAt: serverTimestamp(), // Use Firestore's server timestamp
    });
    console.log("Customer added successfully!");
  } catch (error) {
    console.error("Error adding customer document:", error);
    throw error;  // Throwing error to handle it in the caller function
  }
};

/**
 * Function to redirect the user to a Google Form.
 * Update the URL to point to your specific form.
 */
export const redirectToGoogleForm = () => {
  const googleFormURL = "#"; // Replace with your Google Form link
  window.location.href = googleFormURL;
};

/**
 * Function to save data to Firebase Realtime Database
 * @param {string} path - The database path where data will be stored.
 * @param {Object} data - The data to store.
 */
export const saveToDatabase = async (path, data) => {
  try {
    const dbRef = ref(database, path); // Reference to the specified path
    const newRecordRef = push(dbRef); // Create a new record under the path
    await set(newRecordRef, data); // Save data to the Realtime Database
    console.log("Data saved successfully:", data);
  } catch (error) {
    console.error("Error saving to database:", error);
    throw error;
  }
};

/**
 * Function to fetch data from Firebase Realtime Database
 * @param {string} path - The database path to fetch data from.
 * @returns {Promise<Object>} - Returns the fetched data as an object.
 */
export const fetchFromDatabase = async (path) => {
  return new Promise((resolve, reject) => {
    const dbRef = ref(database, path); // Reference to the specified path
    onValue(dbRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        resolve(data);
      } else {
        reject(new Error("No data found"));
      }
    }, reject);
  });
};
